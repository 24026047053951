


















import {
  defineComponent,
  computed,
  useContext,
  useRoute
} from '@nuxtjs/composition-api';
import {
  getSSRRequestFullUrl,
  getMetaTags,
  mediaUrlFallback
} from '~/helpers/utils';
import useUrlFormatter from '~/hooks/useUrlFormatter';
import useGoogleAnalytics from '~/hooks/useGoogleAnalytics';
import { onSSR } from '@vue-storefront/core';
import { CacheTagPrefix } from '@vue-storefront/cache';

export default defineComponent({
  name: 'CmsPage',
  props: {
    urlInfo: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { i18n, res, $config } = useContext();

    const formatUrl = useUrlFormatter();
    const route = useRoute();
    useGoogleAnalytics();

    const isHomePage = computed(() => {
      const locale = i18n.locale;
      const path = route.value.path;
      return (
        path === `/${locale}/` ||
        path === `/${locale}` ||
        path === '/' ||
        path === ''
      );
    });

    // deep copy props.urlInfo.containers to avoid vuex mutation error
    const containers = computed(() => {
      return JSON.parse(JSON.stringify(props.urlInfo?.containers || []));
    });

    onSSR(() => {
      const productTags = containers.value
        .filter((container) => container.code === 'productcarousel_container')
        .reduce((acc, container) => {
          const carouselElement = container.elements.find(
            (element) => element.code === 'productcarousel'
          );
          if (!carouselElement) return acc;
          return [
            ...acc,
            ...carouselElement.products.map(
              (product) => `${CacheTagPrefix.Product}${product.product_id}`
            )
          ];
        }, []);

      res.setHeader(
        'Cache-Tag',
        `CMS,${CacheTagPrefix.View}content,${CacheTagPrefix.View}${
          route.value.fullPath
        }${isHomePage.value ? ',homepage' : ''}${
          productTags?.length ? `,${productTags.join(',')}` : ''
        }`
      );
      res.setHeader(
        'Cache-Control',
        `max-age=${$config.cfBrowserCacheTimeout}, public`
      );
      res.setHeader(
        'Cloudflare-CDN-Cache-Control',
        `max-age=${$config.cfCacheTimeout}, public`
      );
    });

    const breadcrumbs = computed(() => {
      const apiBreadcrumbsContainer =
        containers.value.find((c) => c.code === 'breadcrumb')?.elements?.[0] ||
        null;
      const apiBreadcrumbs =
        apiBreadcrumbsContainer?.urls?.map((url) => ({
          link: url.url,
          text: url.title
        })) || [];
      if (!apiBreadcrumbs || apiBreadcrumbs.length === 0) return [];
      const currentPageTitle = apiBreadcrumbsContainer?.page_title || '';

      return [
        {
          link: formatUrl('/'),
          text: i18n.t('breadcrumbs_home')
        },
        ...apiBreadcrumbs,
        {
          link: '#',
          text: currentPageTitle
        }
      ];
    });

    const structuredData = computed(() => {
      const structuredDataElement =
        containers.value.find((c) => c.code === 'seo_container')
          ?.elements?.[0] || null;
      return structuredDataElement?.plain_text || null;
    });

    return {
      containers,
      structuredData,
      breadcrumbs,
      isHomePage,
      i18n
    };
  },
  head() {
    const pageURL = process.server
      ? getSSRRequestFullUrl(this.$ssrContext.req)
      : window.location.href;

    const canonicalUrl = pageURL.split('?')[0];

    // hreflang tags have to be set manually because the relative url changes per locale
    const hrefLangTags =
      this.urlInfo.cmswebsiteredirects_headless_pages?.map((redirect) => ({
        hid: `hreflang-${redirect.locale}`,
        rel: 'alternate',
        hreflang: redirect.locale,
        href: redirect.url
      })) || [];

    const slug = this.$route.params.slug;
    const pageName = slug ? slug.charAt(0).toUpperCase() + slug.slice(1) : '';
    const pageTitle = this.urlInfo?.meta_title || pageName;

    return {
      title: pageTitle,
      meta: getMetaTags({
        charset: 'utf-8',
        title: pageTitle,
        description: this.urlInfo?.meta_description || '',
        image: mediaUrlFallback(
          '/media/4e067df4-a171-4c3a-8cfb-63cb87a0cbbd/logo%20kamera%20express.svg'
        ),
        url: pageURL,
        locale: this.$i18n.locale,
        type: this.urlInfo?.page_type || ''
      }),
      link: [
        {
          rel: 'canonical',
          href: canonicalUrl
        },
        ...hrefLangTags
      ],
      __dangerouslyDisableSanitizers: ['script'],
      script: [
        {
          type: 'application/ld+json',
          innerHTML: this.structuredData
        }
      ]
    };
  }
});
